import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import React from 'react'
import { NextWebVitalsMetrics } from '../@types/NextWebVitals'
import { UserSessionProvider } from '../provider/SessionProvider'
import '../styles/globals.scss'
import i18n from '../utils/i18n'
import { createLogger } from '../utils/logger'
import '../utils/sentry'

const fileLabel = '_app'
const logger = createLogger({
	label: fileLabel,
})

// See https://github.com/FortAwesome/react-fontawesome#integrating-with-other-tools-and-frameworks
// config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
library.add(faFacebook, faGithub, faInstagram, faLinkedin, faTwitter)

let timeout: NodeJS.Timeout

const start: any = () => {
	timeout = setTimeout(NProgress.start, 100)
}

const done: any = () => {
	clearTimeout(timeout)
	NProgress.done()
}

Router.events.on('routeChangeStart', start)
Router.events.on('routeChangeComplete', done)
Router.events.on('routeChangeError', done)

// /**
//  * WDYR (why-did-you-render) helps locate unnecessary re-renders and fix them
//  * Applied in development environment, on the frontend only
//  *
//  * @see https://github.com/welldone-software/why-did-you-render
//  */
// if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
// 	// eslint-disable-next-line @typescript-eslint/no-var-requires
// 	const whyDidYouRender = require('@welldone-software/why-did-you-render');
// 	// eslint-disable-next-line no-console
// 	console.debug(
// 		'Applying whyDidYouRender, to help you locate unnecessary re-renders during development. See https://github.com/welldone-software/why-did-you-render'
// 	);
// 	whyDidYouRender(React, {
// 		trackAllPureComponents: true,
// 		trackHooks: true,
// 		logOwnerReasons: true,
// 		collapseGroups: true,
// 	});
// }

/**
 * Will be called once for every metric that has to be reported.
 *
 * There are, at minimum, 3 metrics being received (Next.js-hydration, FCP and TTFB)
 * Then, 2 other metrics can be received optionally (FID, LCP)
 *
 * @param metrics
 * @see https://web.dev/vitals/ Essential metrics for a healthy site
 * @see https://nextjs.org/blog/next-9-4#integrated-web-vitals-reporting Initial release notes
 */
export function reportWebVitals(metrics: NextWebVitalsMetrics): void {
	console.debug('metrics', metrics)
	// const { name } = metrics;
	// const count = globalWebVitalsMetric.reportedCount;
	// globalWebVitalsMetric.metrics[name] = metrics;
	// const keysLength = Object.keys(globalWebVitalsMetric.metrics).length;
	// // Temporise analytics API calls by waiting for at least 5 metrics to be received before sending the first report
	// // (because 3 metrics will be received upon initial page load, and then 2 more upon first click)
	// // Then, send report every 2 metrics (because each client-side redirection will generate 2 metrics)
	// if ((count === 0 && keysLength === 5) || (count > 0 && keysLength === 2)) {
	// 	sendWebVitals(globalWebVitalsMetric);
	// 	// Reset and prepare next metrics to be reported
	// 	globalWebVitalsMetric.metrics = {};
	// 	globalWebVitalsMetric.reportedCount++;
	// }
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
	const { locale } = useRouter()

	i18n.changeLanguage(locale)

	return (
		<UserSessionProvider>
			<Component {...pageProps} />
		</UserSessionProvider>
	)
}

export default MyApp
