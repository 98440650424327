import React, { ReactElement, useContext, useMemo } from 'react'

interface UserSessionProps {
	readonly children: ReactElement
}

/**
 * User data stored in the semi-persistent session
 *
 * We use cookies to store our persistent session (instead of browser LocalStorage), so that we may use them from both the client and the server
 *
 * XXX "semi-persistent" stand for "persistent, but may be lost"
 *  As this session is stored in the browser cookies, it may be reused, or it may be lost the next time the user comes back
 */
export type UserSemiPersistentSession = {
	id: string
	deviceId: string
}

/**
 * The UserSessionContext contains all UserSemiPersistentSession properties
 *
 * XXX "Partial" copies all properties from UserSemiPersistentSession and make them all optional
 *
 * @see https://stackoverflow.com/a/40076355/2391795
 * @see https://github.com/Microsoft/TypeScript/blob/ee25cdecbca49b2b5a290ecd65224f425b1d6a9c/lib/lib.es5.d.ts#L1354
 */
export type UserSessionContext = Partial<UserSemiPersistentSession>

/**
 * Patched user data
 *
 * Allow any property allowed in UserSemiPersistentSession,
 * but omit and override those that are required and aren't meant to be updated
 */
export type PatchedUserSemiPersistentSession = {
	// Remove all properties that aren't meant to be overridden
	// Optionally override required properties to make them optional in the patch
} & Omit<UserSemiPersistentSession, 'id' | 'deviceId'>

export type UserSession = UserSessionContext

/**
 * Initial context, used by default until the Context Provider is initialised.
 *
 * @default Empty object, to allow for destructuring even when the context hasn't been initialised (on the server)
 */
const initialContext = {}

/**
 * The userSession is empty by default and will only be filled on the browser,
 * because it relies on data from cookies that are stored on the end user's browser
 *
 * Uses native React Context API, meant to be used from hooks only, not by functional components
 *
 * @example Usage
 *  import userSessionContext from './src/stores/userSessionContext';
 *  const { userSession }: UserSessionContext = React.useContext(userSessionContext);
 *
 * @see https://reactjs.org/docs/context.html
 * @see https://medium.com/better-programming/react-hooks-usecontext-30eb560999f for useContext hook example (open in anonymous browser #paywall)
 */
const UserSessionContext = React.createContext<UserSessionContext>(initialContext)

/**
 * Hook to access the user session data
 *
 * Uses userSessionContext internally (provides an identical API)
 *
 * This hook should be used by components in favor of userSessionContext directly,
 * because it grants higher flexibility if you ever need to change the implementation (e.g: use something else than React.Context, like Redux/MobX/Recoil)
 *
 * @see https://slides.com/djanoskova/react-context-api-create-a-reusable-snackbar#/11
 */
export function useUserSession() {
	const session = useContext(UserSessionContext)

	if (!session) {
		throw new Error('alert must be used within a AlertContext')
	}
	return session
}

export const UserSessionProvider = ({ children }: UserSessionProps) => {
	const session: UserSessionContext = useMemo(() => ({}), [])

	return <UserSessionContext.Provider value={session}>{children}</UserSessionContext.Provider>
}
