import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from '../locales/de/common.json'
import en from '../locales/en/common.json'

const defaultNamespace = 'common'

i18n.use(initReactI18next).init({
	resources: {
		en,
		de,
	},
	debug: false,
	cleanCode: true,
	fallbackLng: 'en',
	ns: [defaultNamespace, 'meta', 'footer', 'navigation', 'imprint', 'policy', 'about', 'contact'], // string or array of namespaces to load
	defaultNS: defaultNamespace, // default namespace used if not passed to translation function
	interpolation: {
		escapeValue: false,
	},
	react: {
		bindI18n: 'languageChanged',
		useSuspense: false, // Not compatible with SSR
	},
	load: 'languageOnly', // Remove if you want to use localization (en-US, en-GB)
})

export default i18n
